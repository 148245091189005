import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
    static values = {
        user: String
    }
    connect() {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('ServiceWorker registered: ', registration);
            var serviceWorker;
            if (registration.installing) {
                serviceWorker = registration.installing;
                console.log('Service worker installing.');
            } else if (registration.waiting) {
                serviceWorker = registration.waiting;
                console.log('Service worker installed & waiting.');
            } else if (registration.active) {

                console.log('Service worker active.');

            }
        }).catch(registrationError => {
            console.log('Service worker registration failed: ', registrationError);
        });

        window.Notification.requestPermission().then(permission => {
            if(permission !== 'granted'){
                throw new Error('Permission not granted for Notification');
            }
        });
        async function getWebPushData() {
            const sw = await navigator.serviceWorker.ready
            const subscription = await sw.pushManager.getSubscription()
            return subscription ? subscription.toJSON() : null
        }
        getWebPushData().then(data => { this.post_push_data(data)})

    }

    post_push_data(data){
        if (data != null && this.userValue !== "") {
            Rails.ajax({
                type: "Post",
                url: `/users/push_subscription`,
                data: "user_id=" + this.userValue +  "&endpoint=" + data.endpoint + "&p256dh=" + data.keys.p256dh + "&auth=" + data.keys.auth
            })
        }
    }





}