import * as bootstrap from 'bootstrap'

import { Controller } from "@hotwired/stimulus"
import fslightbox from 'fslightbox'
// Connects to data-controller="lightbox"
export default class extends Controller {
    static targets = ["restaurant"]

    connect() {
        refreshFsLightbox();
    }
}