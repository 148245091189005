import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="restaurantslist"
export default class extends Controller {
    static targets = ["restaurantsList"]

    connect() {

    }


    stop(event){
        event.stopPropagation()
    }

    details(event){
        Turbolinks.visit("/restaurants/" + event.params["id"])
    }

    setDone(event){
        console.log(event.params)
        Rails.ajax({
            type: "POST",
            url: `/restaurants/` + event.params["id"] + `/set_done.js`,
            data: "value=" + event.params["value"],
            success: (_data, _status, xhr) => {
                console.log(xhr)
                this.restaurantsListTarget.innerHTML = xhr.response
            }
        })
    }

    setUndone(event){
        console.log(event.params)
        Rails.ajax({
            type: "POST",
            url: `/restaurants/` + event.params["id"] + `/set_done.js`,
            data: "value=" + event.params["value"],
            success: (_data, _status, xhr) => {
                console.log(xhr)
                this.restaurantsListTarget.innerHTML = xhr.response
            }
        })
    }

}