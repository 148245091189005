// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as bootstrap from 'bootstrap';
// import "bootstrap"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "../controllers/index"
// import Lightbox from 'bs5-lightbox'
import "@fortawesome/fontawesome-free/css/all"
import "../js/hope-ui"



// require("../stylesheets/application.scss")

require("../stylesheets/hope-ui.scss")
require("../stylesheets/custom.scss")
require("../stylesheets/dark.scss")
require("../stylesheets/rating.scss")
// require('fslightbox');
require.context('../images', true)
Rails.start()
Turbolinks.start()
ActiveStorage.start()


// window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then(registration => {
//         console.log('ServiceWorker registered: ', registration);
//
//         var serviceWorker;
//         if (registration.installing) {
//             serviceWorker = registration.installing;
//             console.log('Service worker installing.');
//         } else if (registration.waiting) {
//             serviceWorker = registration.waiting;
//             console.log('Service worker installed & waiting.');
//         } else if (registration.active) {
//             serviceWorker = registration.active;
//             console.log('Service worker active.');
//         }
//     }).catch(registrationError => {
//         console.log('Service worker registration failed: ', registrationError);
//     });
// });
