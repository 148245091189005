import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ["results", "form", "query"]
  connect() {

  }

  search(event){
    Rails.fire(this.formTarget, 'submit')

  }

  handleResults() {
    const [data, status, xhr] = event.detail
    // console.log(xhr.response)
    this.resultsTarget.innerHTML = xhr.response
  }



}
