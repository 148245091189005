import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="randomRestaurant"
export default class extends Controller {
    static targets = ["restaurant"]

    connect() {

    }

    randomize(){
        Rails.ajax({
            type: "GET",
            url: `/restaurants/random`,
            success: (_data, _status, xhr) => {
                this.restaurantTarget.innerHTML = xhr.response
            }
        })
    }

}