import { Controller } from "@hotwired/stimulus"
import counterUp from 'counterup2'
import 'waypoints/lib/noframework.waypoints.min.js';


export default class extends Controller {
    connect() {
        const el = document.querySelector( '.counter' )
        new Waypoint( {
            element: el,
            handler: function() {
                counterUp( el )
                this.destroy()
            },
            offset: 'bottom-in-view',
        } )
    }
}