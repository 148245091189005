import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

// Connects to data-controller="lightbox"
export default class extends Controller {
    static targets = ["btnCollapse"]


    connect() {
        if (this.hasBtnCollapseTarget){
            var btn = this.btnCollapseTarget
            var myCollapsible = document.getElementById(btn.dataset.bsTarget.split('#')[1])
            console.log(btn.dataset.bsTarget)
            // collapse geschlossen
            myCollapsible.addEventListener('hidden.bs.collapse', function (event) {
                btn.classList.remove("btn-outline-dark")
                btn.classList.add("btn-dark")
                btn.innerHTML = "<i class=\"fas " + event.target.dataset.collapsedBtnIcon +" me-1\"></i> " + event.target.dataset.collapsedBtnText
            })
            // collapse offen
            myCollapsible.addEventListener('show.bs.collapse', function (event) {
                btn.classList.remove("btn-dark")
                btn.classList.add("btn-outline-dark")
                btn.innerHTML = "<i class=\"fas " + event.target.dataset.expandedBtnIcon +" me-1\"></i> " + event.target.dataset.expandedBtnText
            })
        }
    }


}