import * as bootstrap from 'bootstrap'
import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="toast"
export default class extends Controller {
    connect(){
        var toastElList = [].slice.call(document.querySelectorAll('.toast'))
        var toastList = toastElList.map(function (toastEl) {
            return new bootstrap.Toast(toastEl).show() // No need for options; use the default options
        })
    }

}